import packageJson from '../package.json';

export const clearCachesIfNeeded = () => {
    let version = localStorage.getItem('version');
    if (version !== packageJson.version) {
        console.log(`Server app version: ${packageJson.version}. Clearing caches`);
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }

        localStorage.clear();
        localStorage.setItem('version', packageJson.version);
    }
};
