import React from "react";
import {FC} from "react";
import {useWatcher} from "./WatcherContext";
import WatcherInfoView from "./WatcherInfoView";
import WatcherSelectView from "./WatcherSelectView";

const WatchersView: FC = () => {

    const watcher = useWatcher();

    return watcher ?
        <WatcherInfoView/>
        :
        <WatcherSelectView/>

}

export default WatchersView;
