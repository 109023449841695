import React, {createContext, useContext, useReducer} from "react";
import {Watcher} from "./types";


export const WatcherContext = createContext(null);
export const WatcherDispatchContext = createContext((action: {type: string, watcher?: Watcher})=> {});

function watcherReducer(watcher, action) {
    switch (action.type) {
        case 'set':
            return action.watcher
        case 'unset':
            return null
        default:
            return null
    }
}

export function WatcherProvider({children}){
    const [watcher, dispatch] = useReducer(watcherReducer, null)
    return (
        <WatcherContext.Provider value={watcher}>
            <WatcherDispatchContext.Provider value={dispatch}>
                {children}
            </WatcherDispatchContext.Provider>
        </WatcherContext.Provider>
    )
}

export function useWatcher() {
    return useContext(WatcherContext)
}

export function useWatcherDispatch() {
    return useContext(WatcherDispatchContext)
}
