import React, {FC, lazy, Suspense, useState} from "react";
import {Box, Grid, Paper, Typography} from "@mui/material";
import WatcherInfoMenu from "./WatcherInfoMenu";
import Loading from "./Loading";
import FlybysView from "./FlybysView";
import ActivityInfoView from "./ActivityInfoView";
import DetectionsView from "./DetectionsView";
import {useWatcher} from "./WatcherContext";

const WatcherInfoView: FC = () => {
    const [view, setView] = useState('flybys')

    const StatsView = lazy(() => import("./StatsView"));

    const watcher = useWatcher();

    return (
        <Grid container padding={1} spacing={1} height={1} overflow={'hidden'}>
            <Grid item sm={2} xs={3} height={1}>
                <Paper>
                    <Box padding={1} alignItems={'baseline'}>
                        <Typography variant={'h6'}>{watcher?.name}</Typography>
                    </Box>
                </Paper>
                <Paper sx={{height: 0.90, mt: 1}}>
                    <WatcherInfoMenu onSelect={setView}/>
                </Paper>
            </Grid>
            <Grid item sm={10} xs={9} height={1}>

                <Paper sx={{height: 0.99}}>
                    <Suspense fallback={<Loading/>}>
                        {
                            (view === 'flybys' && <FlybysView/>)
                            ||
                            (view === 'activity' && <ActivityInfoView/>)
                            ||
                            (view === 'detections' && <DetectionsView/>)
                            ||
                            (view === 'stats' && <StatsView/>)
                            ||
                            <></>
                        }
                    </Suspense>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default WatcherInfoView;
